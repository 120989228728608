<template>
  <MainContainer
    :style="currentPageMaxWidth"
    :main-content-options="mainContentOptions"
    :drawer-options="drawerOptions"
    :route="$route"
    :router="$router"
    :user-name="userName"
    :language="language.lang"
    :app-version="$version"
    :is-main-page="$route.name === 'Home'"
    :content-padding="layoutConfig.padding"
    :content-margin="layoutConfig.margin"
    origin="user"
    @open-support="handleOpenSupport"
    @open-notification-adjustments="handleOpenNotificationsAdjustments"
    @open-feedback="handleOpenFeedback"
    @open-shared-accounts="handleSharedAccounts"
    @sign-out="handleSignOut"
  >
    <template v-if="$route.name === 'Search'" v-slot:header-content>
      <FilterCarrousel
        :title="$translations.search['filter-by'].by"
        :options="filterOptions"
        :selection="filterQuery"
        :loading="filterLoading"
        :segment="filterSegment"
        :language="language.lang"
        @confirm="handleFilterConfirmSelection"
      />
    </template>

    <template v-slot:before-content>
      <!-- top tab bar -->
      <SliderNav
        v-if="$route.name === 'Agenda'"
        :options="sliderNavOptions.appointments"
        :route="$route"
      />
    </template>

    <template v-slot:content>
      <router-view />

      <!-- notifications -->
      <NotificationHandler
        v-if="$firestore && $store.getters['auth/user']"
        :user-id="$store.getters['auth/user'].id"
        :firestore="$firestore"
        :store="$store"
        :lang="language.lang"
        @call-to-action="handleNotificationCallToAction"
        @emit-event="handleNotificationEvent"
      />
    </template>
  </MainContainer>
</template>

<script>
import {
  MainContainer,
  SliderNav,
  FilterCarrousel,
  RoleType,
  getClientTimezone,
  NotificationHandler
} from '@seliaco/red-panda'

import { mapGetters } from 'vuex'
import MainContentMixin from '@/mixins/main-content-mixin'
import { Browser } from '@capacitor/browser'
import {
  SupportEvent,
  Acquisition,
  DrawerEvent,
  SpecialistSearch,
  NotificationEvent
} from '@/types/events'

export default {
  name: 'Main',
  components: {
    MainContainer,
    SliderNav,
    FilterCarrousel,
    NotificationHandler
  },
  mixins: [MainContentMixin],
  mounted () {
    if (this.user) {
      this.mounted()
    } else {
      this.$store.watch(
        () => this.user,
        () => {
          this.mounted()
        }
      )
    }

    if (this.settings.conferenceModule) {
      this.getSliderNavOptions()
    } else {
      this.$store.watch(
        () => this.settings.conferenceModule,
        () => {
          this.getSliderNavOptions()
        }
      )
    }
  },
  methods: {
    mounted () {
      const timezoneLocal = localStorage.getItem('skip-timezone')
      const clientTimezone = getClientTimezone()

      if (!timezoneLocal) {
        localStorage.setItem('skip-timezone', this.user.time_zone)
      }

      if (this.user.time_zone !== clientTimezone) {
        if (timezoneLocal !== clientTimezone) {
          this.$globalEvent.$emit('modal/timezone', {
            showDialog: { timezone: { open: true, close: false } }
          })
        }
      }

      this.loadEvents()
    },
    loadEvents () {
      this.$store.watch(
        () => this.unreadMessages,
        () => {
          if (this.unreadMessages) {
            enableChatEvents()
          }
        }
      )

      const enableChatEvents = async () => {
        const messageTabIndex = this.mainContentOptions.findIndex(
          (o) => o.route.name === 'Messages'
        )
        if (messageTabIndex !== -1) {
          this.mainContentOptions[messageTabIndex].badge = this.unreadMessages
        }
      }
    },
    handleSignOut () {
      this.$globalEvent.$emit('chat/disconnect')
      DrawerEvent.Drawer_Click_Option('Sign Out', this.$route.name)

      this.removeFromLocalStorage()

      this.$store.commit('UPDATE_NEXT_APPOINTMENT', null)

      Acquisition.logout(this.user)

      this.$auth.signOut()
      this.$kustomer.signOut(this.$store.getters.platform)

      this.$router.push({
        name: 'SignIn',
        query: this.$route.query || null
      })
    },
    handleOpenSupport () {
      DrawerEvent.Drawer_Click_Option('Open support', this.$route.name)

      this.$kustomer.openChat(this.$store.getters.platform)
      this.$emit('close')

      SupportEvent.clickSupport({
        user: this.user,
        source: 'Profile'
      })
    },
    handleOpenNotificationsAdjustments () {
      DrawerEvent.Drawer_Click_Option(
        'Notifications settings',
        this.$route.name
      )

      this.$router.push({
        name: 'NotificationSettings'
      })
    },
    handleOpenFeedback () {
      DrawerEvent.Drawer_Click_Option('Open feedback', this.$route.name)

      const formUrl = 'https://selialatam.typeform.com/to/AuiiKnsZ'
      const userId = this.user.id || 'guest'

      Browser.open({
        url: `${formUrl}#user_id=${userId}`
      })
    },
    handleSharedAccounts () {
      DrawerEvent.Drawer_Click_Option('Shared accounts', this.$route.name)
      if (this.sharedAccount) {
        this.$router.push({
          name: 'SharedAccounts'
        })
      } else {
        this.$globalEvent.$emit('modal/shared-account-start', {
          showDialog: { sharedAccountStartModal: { open: true, close: false } }
        })
      }
    },
    handleFilterConfirmSelection (selection) {
      this.$store.commit('filtersV2/SET_LOADING', {
        key: 'results',
        value: true
      })

      this.$store.dispatch('filtersV2/setQuery', {
        values: selection,
        cleanedRequest: true
      })
    },
    handleNotificationCallToAction (url) {
      const isExternalUrl = url.includes('http')

      if (isExternalUrl) {
        return Browser.open({ url })
      }

      return this.$router.push(url)
    },
    getSliderNavOptions () {
      this.sliderNavOptions.appointments = [
        {
          text: this.$translations.navbar['my-appointments'],
          name: 'appointments',
          action: () => {
            this.$router.replace({
              query: {
                ...this.$route.query,
                tab: 'appointments'
              }
            })
          }
        }
      ]
      if (
        this.$store.getters['settings/getSettings'].conferenceModule &&
        RoleType.USER_HIDE_PRICES !== this.role
      ) {
        this.sliderNavOptions.appointments = [
          ...this.sliderNavOptions.appointments,
          {
            text: this.$translations.navbar['my-conferences'],
            name: 'conferences',
            action: () => {
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  tab: 'conferences'
                }
              })
            }
          }
        ]
      }
    },
    removeFromLocalStorage () {
      localStorage.removeItem('SeliaWelcomeBanner')
      localStorage.removeItem('SliderOnboardingCompleted')
      localStorage.removeItem('OnboardingWelcomeDialog')
      localStorage.removeItem('ShowObSlider')
    },
    handleNotificationEvent (event) {
      const body = {
        ...event.props,
        route: this.$route.name
      }

      switch (event.eventName) {
        case 'open':
          return NotificationEvent.InAppMessageViewed(body)
        case 'close':
          return NotificationEvent.InAppMessageDismissed(body)
        case 'cdt':
          return NotificationEvent.InAppMessageClicked(body)
      }
    }
  },
  computed: {
    ...mapGetters({
      filterLoading: 'filtersV2/loading',
      filterOptions: 'filtersV2/options',
      filterQuery: 'filtersV2/query',
      user: 'auth/user',
      role: 'auth/role',
      language: 'language',
      settings: 'settings/getSettings',
      sharedAccount: 'sharedAccount/sharedAccountId',
      unreadMessages: 'chat/unreadMessages'
    }),
    userName () {
      if (this.user?.first_name) {
        return `${this.user?.first_name} ${this.user?.last_name}`
      }

      return ''
    },
    currentPageMaxWidth () {
      if (this.$route.name === 'Messages') {
        return {
          '--max-width': 'unset'
        }
      }

      return {
        '--max-width': '1040px'
      }
    },
    layoutConfig () {
      return {
        padding: this.$route.name === 'Search' ? '110px' : '52px',
        margin: this.$route.name === 'Search' ? '62px' : '0px'
      }
    },
    filterSegment () {
      return {
        handleHeaderAction: SpecialistSearch.Specialists_Filter_Clean,
        handleCloseAction: SpecialistSearch.Specialists_Filter_Discard,
        handleConfirmAction: SpecialistSearch.Specialists_Filter_Apply
      }
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep
  .slider-nav
    position: fixed
    top: calc(52px + env(safe-area-inset-top))
    &-containter
      margin: initial

@media (min-width: 1040px)
  ::v-deep
    .slider-nav
      width: calc(100% - 200px)

@media (min-width: 1450px)
  ::v-deep
    .slider-nav
      left: 50%
      transform: translateX(-50%)
      &-containter
        margin: 0 auto
</style>
