import {
  /* SupportEvent, */ MenuOptions,
  DrawerEvent,
  SpecialistSearch,
  ForYouEvent
} from '@/types/events'
import { RoleType } from '@seliaco/red-panda'

export default {
  data () {
    const drawerOptions = [
      {
        icon: 'icon-person-off',
        text: this.$translations.drawer.profile,
        action: () => {
          DrawerEvent.Drawer_Click_Option('Profile', this.$route.name)
          this.$router.push({ name: 'Profile' })
        },
        hideDivider: true
      },
      {
        icon: 'icon-my-package-on',
        text: this.$translations.drawer['my-packages'],
        action: () => {
          DrawerEvent.Drawer_Click_Option('My Packages', this.$route.name)
          this.$router.push({ name: 'MyPackages', query: { tab: 'actives' } })
        },
        hideDivider: true
      },
      {
        icon: 'icon-give',
        text: this.$translations.drawer.referrals,
        action: () => {
          DrawerEvent.Drawer_Click_Option('Refer a friend', this.$route.name)
          this.$router.push({ name: 'Referrals' })
        },
        hideDivider: true
      },
      {
        icon: 'icon-creditcard-off',
        text: this.$translations.drawer.payments,
        action: () => {
          DrawerEvent.Drawer_Click_Option('Payments List', this.$route.name)
          this.$router.push({ name: 'PaymentsList' })
        }
      },
      {
        icon: 'icon-specialists-off',
        text: this.$translations.drawer['my-specialists'],
        action: () => {
          DrawerEvent.Drawer_Click_Option('My Specialists', this.$route.name)
          this.$router.push({
            name: 'MySpecialists',
            query: { tab: 'history', back: 'Home' }
          })
        },
        hideDivider: true
      },
      {
        icon: 'icon-recommended-off',
        text: this.$translations.drawer['my-recommended'],
        action: () => {
          DrawerEvent.Drawer_Click_Option('My Recommended', this.$route.name)
          this.$router.push({
            name: 'MySpecialists',
            query: { tab: 'recommended', back: 'Home' }
          })
        },
        hideDivider: true
      },
      {
        icon: 'icon-heart-off',
        text: this.$translations.drawer['my-favorites'],
        action: () => {
          DrawerEvent.Drawer_Click_Option(
            'My Favorite Specialists',
            this.$route.name
          )
          this.$router.push({
            name: 'MySpecialists',
            query: { tab: 'favorites', back: 'Home' }
          })
        }
      }
    ]

    const mainContentOptions = [
      {
        icon: {
          on: 'icon-home-on',
          off: 'icon-home-off'
        },
        route: {
          path: '/',
          name: 'Home',
          query: null,
          params: null
        },
        title: this.$translations.navbar.home,
        navigationAction: () => {
          MenuOptions.clickMenu({
            user: this.$store.getters['auth/user'],
            option: 'HOME'
          })
        },
        showLogo: true
      },
      {
        icon: {
          on: 'icon-appointment-on',
          off: 'icon-appointment-off'
        },
        route: {
          path: '/agenda?tab=appointments',
          name: 'Agenda',
          query: null,
          params: null
        },
        title: this.$translations.navbar['my-appointments'],
        navigationAction: () => {
          MenuOptions.clickMenu({
            user: this.$store.getters['auth/user'],
            option: 'MY-APPOINTMENTS'
          })
        }
      },
      {
        icon: {
          on: 'icon-play-circle-on',
          off: 'icon-play-circle-off'
        },
        route: {
          path: '/for-you',
          name: 'ForYou',
          query: null,
          params: null
        },
        navigationAction: async () => {
          MenuOptions.clickMenu({
            user: this.$store.getters['auth/user'],
            option: 'FOR-YOU'
          })
        },
        title: this.$translations.navbar['for-you'],
        headerAction: [
          {
            icon: 'icon-search-off',
            callback: () => {
              this.$router
                .push({
                  name: 'ForYouSearch',
                  query: {
                    back: this.$route.fullPath
                  }
                })
                .then(() => {
                  ForYouEvent.searchButtonOpen()
                })
            }
          }
          // TODO: view foryou favorite
          // {
          //   icon: 'icon-heart-off',
          //   callback: () => {
          //   }
          // }
        ]
      },
      {
        icon: {
          on: 'icon-search-on',
          off: 'icon-search-off'
        },
        route: {
          path: '/search?on_focus=true',
          name: 'Search',
          query: {
            directory_origin: 'NavBar'
          },
          params: null
        },
        navigationAction: async () => {
          MenuOptions.clickMenu({
            user: this.$store.getters['auth/user'],
            option: 'SPECIALISTS'
          })
        },
        title: this.$translations.navbar.specialists,
        headerAction: [
          {
            icon: 'icon-search-off',
            callback: () => {
              this.$router
                .push({
                  name: 'SpecialistsSearch',
                  query: {
                    back: this.$route.fullPath
                  }
                })
                .then(() => {
                  SpecialistSearch.Specialists_Search_Open()
                })
            }
          },
          {
            icon: 'icon-heart-off',
            callback: () => {
              this.$router.push({
                name: 'MySpecialists',
                query: { tab: 'favorites', back: 'Search' }
              })
            }
          }
        ]
      },
      {
        icon: {
          on: 'icon-message-circle-on',
          off: 'icon-message-circle-off'
        },
        route: {
          path: '/messages',
          name: 'Messages'
        },
        navigationAction: () => {
          MenuOptions.clickMenu({
            user: this.$store.getters['auth/user'],
            option: 'CHAT'
          })
        },
        title: this.$translations.navbar.chat,
        badge: null
      }
      // {
      //   icon: {
      //     on: 'icon-support-on',
      //     off: 'icon-support-off'
      //   },
      //   route: {
      //     name: 'Support',
      //     query: null,
      //     params: null
      //   },
      //   navigationAction: async () => {
      //     await this.$kustomer.openChat(this.$store.getters.platform)
      //     SupportEvent.clickSupport({
      //       user: this.$store.getters['auth/user'],
      //       source: 'main menu'
      //     })
      //   },
      //   title: this.$translations.navbar.support
      // }
    ]

    return {
      /**
       * Each route should have the following structure
       *
       * {
       *   icon: {
       *     on: String,
       *     off: String
       *   },
       *   route: {
       *     path: String,
       *     name: String // unique string,
       *     query: String,
       *     params: String
       *   },
       *   title: String,
       *   showLogo: Boolean,
       *   navigationAction: Function,
       *   headerActions: [
       *     Function
       *   ],
       *   badge: Number
       * }
       */
      mainContentOptions,
      /**
       * Each drawer option should have the following structure
       *
       *  [
       *    {
       *       icon: String,
       *       text: String,
       *       action: Function,
       *       hideDivider: Boolean
       *    }
       *  ]
       */
      drawerOptions,
      sliderNavOptions: { appointments: [] }
    }
  },
  created () {
    if (RoleType.USER_HIDE_PRICES === this.role) {
      const enableOptions = [
        this.$translations.drawer.profile,
        this.$translations.drawer['my-specialists'],
        this.$translations.drawer['my-favorites']
      ]

      this.drawerOptions = this.drawerOptions.filter((d) =>
        enableOptions.includes(d.text)
      )
    }
  },
  watch: {
    $route (route) {
      this.mainContentOptions = this.mainContentOptions.map((option) => {
        if (option.route.name === route.name) {
          option.route.query = route.query
          option.route.params = route.params
        } else {
          option.route.query = null
          option.route.params = null
        }
        return option
      })
      if (route.name === 'Agenda') {
        if (!route.query.tab) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              tab: 'appointments'
            }
          })
        }
      }
    }
  }
}
